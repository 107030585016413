// import Vue from 'vue'
const getters = {};
const state = {
  baseurllocation: "/",
  sidebarOpenInfo: false,
  sidebarSite: false,
  openPopupSite: false,
  focusSite: false,
  sidebarFocusList: false,
  closeMenu: false,
  navTabState: 0
};
const mutations = {
  /*
  updateCloseMenu(state, newValue) {
    state.closeMenu = newValue;
  },
  updateFocusSite(state, focusSite) {
    state.focusSite = focusSite;
    state.sidebarSite = focusSite;
  },
  updateOpenPopupSite(state, openPopupSite) {
    state.openPopupSite = openPopupSite;
  },
  updateSidebarSite(state, focusSite) {
    state.sidebarSite = focusSite;
  },
  updateSidebarOpenToInfo(state) {
    state.sidebarFocusList = false;
    state.sidebarOpenInfo = false;
    setTimeout(() => {
      state.sidebarOpenInfo = true;
    }, 0);
  },
  updateSidebarFocusList(state) {
    state.sidebarFocusList = false;
    state.sidebarOpenInfo = false;
    setTimeout(() => {
      state.sidebarFocusList = true;
    }, 0);
  },
  clearSidebarOpenToInfo(state) {
    state.sidebarOpenInfo = false;
  },
*/
  UPDATE_SIDEBAR_OPEN_INFO(state, newValue) {
    state.sidebarOpenInfo = newValue;
  },
  UPDATE_SIDEBAR_SITE(state, newValue) {
    state.sidebarSite = newValue;
  },
  UPDATE_OPEN_POPUP_SITE(state, newValue) {
    state.openPopupSite = newValue;
  },
  UPDATE_FOCUS_SITE(state, newValue) {
    if (state.focusSite != newValue) {
      state.focusSite = newValue;
    } else {
      state.focusSite = -1;
      setTimeout(() => {
        state.focusSite = newValue;
      }, 2);
    }
  },
  UPDATE_SIDEBAR_FOCUS_LIST(state, newValue) {
    state.sidebarFocusList = newValue;
  },
  UPDATE_CLOSE_MENU(state, newValue) {
    state.closeMenu = newValue;
  },
  UPDATE_TAB_STATE(state, newValue) {
    state.navTabState = newValue;
  }
};
const actions = {
  navUpdateCloseMenu({ commit }, newValue) {
    commit("UPDATE_CLOSE_MENU", newValue);
  },
  navUpdateFocusSite({ commit }, focusSite) {
    commit("UPDATE_FOCUS_SITE", focusSite);
    commit("UPDATE_SIDEBAR_SITE", focusSite);
  },

  navUpdateOpenPopupSite({ commit }, openPopupSite) {
    commit("UPDATE_OPEN_POPUP_SITE", openPopupSite);
  },
  navUpdateSidebarSite({ commit }, focusSite) {
    commit("UPDATE_SIDEBAR_SITE", focusSite);
  },

  navUpdateSidebarOpenToInfo({ commit }) {
    commit("UPDATE_SIDEBAR_FOCUS_LIST", false);
    commit("UPDATE_SIDEBAR_OPEN_INFO", false);
    setTimeout(() => {
      commit("UPDATE_SIDEBAR_OPEN_INFO", true);
    }, 0);
  },
  navUpdateSidebarFocusList({ commit }) {
    commit("UPDATE_SIDEBAR_FOCUS_LIST", false);
    commit("UPDATE_SIDEBAR_OPEN_INFO", false);
    setTimeout(() => {
      commit("UPDATE_SIDEBAR_FOCUS_LIST", true);
    }, 0);
  },
  navClearSidebarOpenToInfo({ commit }) {
    commit("UPDATE_SIDEBAR_OPEN_INFO", false);
  },
  navSetTabeState({ commit }, value) {
    commit("UPDATE_TAB_STATE", value);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
