var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.properties && _vm.properties.Site_Name)?_c('v-card',{staticStyle:{"border-radius":"0"}},[(_vm.imageFiles && _vm.imageFiles.length)?_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":_vm.imageFiles.length > 1,"height":_vm.imageSize[_vm.imageType] ? _vm.imageSize[_vm.imageType] : '100px'}},_vm._l((_vm.imageFiles),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":'./images/' + item}})}),1):_vm._e(),_c('div',{staticClass:"sideBarTitle"},[(_vm.properties.Site_Name)?_c('v-card-title',{staticClass:"secondary--text font-weight-regular text--darken-1"},[_vm._v(_vm._s(_vm.properties.Site_Numberx ? _vm.properties.Site_Numberx + " - " : "")+" "+_vm._s(_vm.properties.Site_Name)+" ")]):_vm._e(),(_vm.properties.Site_Artist || _vm.properties.Site_Address)?_c('v-card-subtitle',[(_vm.properties.Site_Artist)?_c('font',{staticClass:"font-weight-normal secondary--text  text--lighten-1"},[_vm._v(_vm._s(_vm.properties.Site_Artist))]):_vm._e(),(_vm.properties.Site_Artist && _vm.properties.Site_Address)?_c('br'):_vm._e(),(_vm.properties.Site_Address)?_c('font',{staticClass:"font-weight-light  secondary--text text--lighten-1"},[_vm._v(_vm._s(_vm.properties.Site_Address))]):_vm._e()],1):_vm._e()],1),_c('AudioPlayButton',{attrs:{"setSound":_vm.setSound,"getAudioDetails":_vm.getAudioDetails,"properties":_vm.properties,"width":_vm.appSettings.drawButtonWidth,"appSettings":_vm.appSettings}}),(_vm.Site_Description)?_c('div',{staticClass:" secondary--text "},[_c('v-card-text',{class:[
          '',
          'pb-1',
          'pt-0',
          'Site_Description',
          _vm.showMore || !_vm.Site_Description_CanCrop ? 'showMore' : 'showLess'
        ]},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.Site_Description)}}),(!_vm.showMore && _vm.Site_Description_CanCrop)?_c('div',{staticClass:"fade"}):_vm._e()]),_c('v-card-text',{staticClass:"pt-0 accent--text "},[(_vm.Site_Description_CanCrop)?_c('a',{staticClass:"accent--text",staticStyle:{"text-decoration":"underline!important"},on:{"click":function($event){_vm.showMore = !_vm.showMore}}},[(_vm.showMore)?_c('font',[_vm._v(" Show Less")]):_vm._e(),(!_vm.showMore)?_c('font',[_vm._v(" Show More")]):_vm._e()],1):_vm._e()])],1):_vm._e(),_vm._l((_vm.detailsToShow),function(item){return _c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.properties[item.field]),expression:"properties[item.field]"}],key:item.field,staticClass:"py-0 xxgrey--text "},[_c('span',{staticClass:"font-weight-medium text-uppercase"},[_vm._v(" "+_vm._s(item.title)+": ")]),(_vm.properties[item.field])?_c('span',{},[(
            item.url &&
              _vm.properties[item.url] &&
              _vm.properties[item.url].includes('http')
          )?_c('a',{attrs:{"href":_vm.properties[item.url],"target":"_blank"}},[_vm._v(_vm._s(_vm.properties[item.field]))]):_c('font',[_vm._v(_vm._s(_vm.properties[item.field]))])],1):_vm._e()])}),_c('VideoPlayButton',{attrs:{"openYoutube":_vm.openYoutube,"properties":_vm.properties,"width":_vm.appSettings.drawButtonWidth,"appSettings":_vm.appSettings}}),(_vm.properties.youTubeUrl_2)?_c('VideoPlayButton',{attrs:{"openYoutube":_vm.openYoutube,"properties":_vm.properties,"width":_vm.appSettings.drawButtonWidth,"appSettings":_vm.appSettings,"altButton":"2"}}):_vm._e(),(_vm.properties.youTubeUrl_3)?_c('VideoPlayButton',{attrs:{"openYoutube":_vm.openYoutube,"properties":_vm.properties,"width":_vm.appSettings.drawButtonWidth,"appSettings":_vm.appSettings,"altButton":"3"}}):_vm._e(),(_vm.properties.linksAct && _vm.properties.linksAct.length)?_c('v-card-text',[_c('strong',[_vm._v("LINKS:")]),_vm._l((_vm.properties.linksAct),function(link){return _c('p',{key:link.title,staticClass:"pa-0 ma-1 "},[_c('a',{staticClass:"accent--text",staticStyle:{"text-decoration":"underline!important"},attrs:{"href":link.url,"target":"_blank"}},[_vm._v(" "+_vm._s(link.title)+" ")])])})],2):_vm._e(),_c('v-card-text',[_c('br')])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }