<template>
  <div>
    <v-btn
      depressed
      v-if="youTubeUrl"
      :color="appSettings.mediaButtonColor"
      :width="width"
      class="ma-2 white--text"
      @click="openYoutube(youTubeUrl, properties.Site_Name)"
    >
      <v-icon left>mdi-youtube-tv</v-icon>
      {{ buttonTitle }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "VideoPlayButton",
  computed: {
    youTubeUrl() {
      let youTubeUrl = this.properties.youTubeUrl;
      if (this.altButton) {
        if (this.altButton == "2" && this.properties.youTubeUrl_2) {
          youTubeUrl = this.properties.youTubeUrl_2;
        } else if (this.altButton == "3" && this.properties.youTubeUrl_3) {
          youTubeUrl = this.properties.youTubeUrl_3;
        } else {
          youTubeUrl = false;
        }
      }
      return youTubeUrl;
    },
    buttonTitle() {
      let title = "Play Video";

      if (this.altButton == "2" && this.properties.youTubeUrl_2) {
        title = "Play 2nd Video";
      } else if (this.altButton == "3" && this.properties.youTubeUrl_3) {
        title = "Play 3rd Video";
      } else if (!this.altButton && this.properties.youTubeUrl_2) {
        title = "Play 1st Video";
      }

      return title;
    }
  },
  data: () => ({
    ready: false,
    audioPlayIcon: "false"
  }),
  props: {
    openYoutube: Function,
    width: String,
    appSettings: Object,
    properties: {
      required: true,
      type: Object
    },
    altButton: String
  },
  methods: {},
  async mounted() {}
};
</script>
