<template>
  <div class="playerWrapper">
    <iframe
      :id="'main'"
      width="100px"
      height="200px"
      scrolling="no"
      frameborder="no"
      allow="autoplay"
      :src="playerUrl"
      :class="{ hiddenPlayer: currentTrack == initTrack }"
    ></iframe>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SoundCloudPlayer",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,

      initTrack: state => state.media.initTrack,
      isPlaying: state => state.media.isPlaying,
      currentTrack: state => state.media.currentTrack,
      command: state => state.media.command,
      markerList: state => state.markers.markerList,
      playingSiteName: state => state.media.playingSiteName

      //sidebarSite: state => state.navigation.sidebarSite
    }),
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    },
    playerUrl() {
      return this.getPlayerUrl(this.currentTrack, false);
    },
    sites() {
      return this.markerList.data.features;
    }
  },
  data: () => ({
    items: [],
    widget: null,
    iframe: null,
    tryRePlay: true
  }),
  methods: {
    checkIsPlaying() {
      let _this = this;

      try {
        if (this.widget) {
          this.widget.isPaused(function isPaused(paused) {
            try {
              _this.widget.getPosition(function isPaused(position) {
                if (paused === true && _this.isPlaying === true) {
                  _this.$store.dispatch("mediaUpdateIsPlaying", false);
                } else if (
                  paused === false &&
                  _this.isPlaying === false &&
                  position
                ) {
                  _this.$store.dispatch("mediaUpdateIsPlaying", true);
                }
              });
            } catch (error) {
              //do nothing;
            }
          });
        }
      } catch (error) {
        //do nothing;
      }
    },

    ready() {},
    getPlayerUrl(track, autoplay) {
      let color = this.appSettings.audioPlayColor
        ? this.appSettings.audioPlayColor
        : "ff5500";

      if (autoplay === true) {
        return `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${track}?auto_play=false`;
      } else {
        return `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${track}?auto_play=false&color=%23${color}&theme_color=%23${color}&hide_related=true&buying=false&sharing=false&download=false&show_comments=false&&liking=false&show_user=false&show_reposts=false&show_teaser=false&visual=false&show_playcount=false&single_active=true&show_artwork=false&start_track=false`;
      }
    },
    initPlayer() {
      this.widget = window.SC.Widget("main");
      this.iframe = document.getElementById("main");
      this.widget.setVolume(100);
    },

    updateSoundTitle() {}
  },
  async mounted() {
    setTimeout(() => {
      this.initPlayer();
    }, 1000);
    setInterval(() => {
      this.checkIsPlaying();
      //console.log("check is playing!!!!");
    }, 500);
  },

  watch: {
    currentTrack: function(newValue) {
      this.updateSoundTitle();

      if (this.widget) {
        this.$store.dispatch("mediaUpdateIsPlaying", false);
        let url = this.getPlayerUrl(newValue, true);
        let options = {};
        options.auto_play = true;

        options.callback = () => {
          if (!this.isIOS) {
            this.widget.play();
            this.$store.dispatch("mediaUpdateIsPlaying", true);
          }
          setTimeout(() => {
            if (!this.isIOS) {
              this.widget.play();
              this.$store.dispatch("mediaUpdateIsPlaying", true);
            }
          }, 30);
        };
        this.widget.load(url, options);
      }
    },
    command: function(newValue /*, oldValue*/) {
      this.tryRePlay = false;
      try {
        if (newValue) {
          if (this.widget) {
            switch (newValue) {
              case "play":
                this.widget.play();
                this.$store.dispatch("mediaUpdateIsPlaying", true);
                break;
              case "pause":
                this.widget.pause();
                this.$store.dispatch("mediaUpdateIsPlaying", false);
                break;
              case "toggle":
                this.widget.toggle();
                this.$store.dispatch("mediaUpdateIsPlaying", !this.isPaused);

                setTimeout(() => {
                  this.widget.isPaused(tempIsPaused => {
                    this.$store.dispatch("mediaUpdateIsPlaying", !tempIsPaused);
                  });
                }, 100);
                setTimeout(() => {
                  this.widget.isPaused(tempIsPaused => {
                    this.$store.dispatch("mediaUpdateIsPlaying", !tempIsPaused);
                  });
                }, 500);

                break;
              default:
              // code block
            }
          }
        }
      } catch (error) {
        //console.log("error in command ", newValue);
      }

      this.$store.dispatch("mediaUpdateCommand", false);
    }
  }
};
</script>
<style>
.playerWrapper {
  height: 56px;
  width: 100px;
  overflow: hidden;
}

.playerWrapper iframe.hiddenPlayer {
  display: none;
}
.playerWrapper iframe {
  -ms-zoom: 0.9;
  -moz-transform: scale(0.9);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.9);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.9);
  -webkit-transform-origin: 0 0;
}

@media only screen and (max-width: 640px) {
  .playerWrapper {
    height: 56px;
    width: 80px;
    overflow: hidden;
  }

  .playerWrapper iframe {
    -ms-zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.8);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    -webkit-transform-origin: 0 0;
  }
}
</style>
