<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndDown ? 500 : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="secondary--text "> About </span>
      </template>

      <v-card>
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center"
        >
          The Journey of Extraordinary Encounters
        </v-card-title>
        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row no-gutters class="contentWrapper  secondary--text " style="">
            <v-spacer></v-spacer>

            <v-col md="8" cols="12" class="text-center justify-center">
              <v-card-text
                class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
              >
                This Interactive Map was part of Geelong Design Week 2021, an
                initiative of Geelong UNESCO City of Design and the City of
                Greater Geelong.
              </v-card-text>
            </v-col>
            <v-spacer></v-spacer>

            <v-col md="6" cols="12">
              <v-card-text>
                <p>
                  Enter the world of nature’s design principles, following an
                  interactive trail to uncover biophilic design in the city and
                  experience what nature can teach us about how to live better
                  in the urban environment.
                </p>
                <p>
                  This event was a deconstructed exhibition and experience
                  across three locations, Johnstone Park, Little Malop Street
                  precinct and the Geelong Botanic Gardens. The nature of the
                  event is open-space walking so everyone can participate in a
                  COVID-safe way.
                </p>

                <p>
                  The trail includes direct experiences of nature, hidden design
                  gems and the work of leading artists and designers in windows
                  and places in the city, bringing these exciting ideas to life.
                </p>

                <p>
                  Sites 1-10 and 36-49 form a self-guided exploration of
                  biophilic design with narration. Other sites 11, 15, 16, 22,
                  24, 25, 26, 28, 28A, 29, 30, 30A, 31A, 35 and 45 formed an
                  exhibition during Geelong Design Week (2021) and while no longer
                  physically present can be viewed virtually on the map.
                </p>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                <p>
                  The interactive map, with augmented reality leads adults and
                  children on an exciting design journey, inviting us to imagine
                  Geelong as one of the great nature-based cities of the world.
                </p>

                <p class="">
                  It is narrated by Geelong based actor, playwright and
                  Gunditjmara man, Tom Molyneux.
                </p>
                <p class="mb-1">
                  This event was designed and created by Dr Mary-Jane Walker of
                  the School of Lost Arts using the Maptivate Platform (<a
                    href="https://www.maptivate.com.au/"
                    target="_blank"
                    >maptivate.com.au</a
                  >) and presented by:
                </p>
                <p v-for="link in links" :key="link.title" class="pa-0 ma-1">
                  <a
                    :href="link.url"
                    target="_blank"
                    style="text-decoration: underline!important;"
                  >
                    <v-btn
                      class="text-left"
                      color="accent"
                      text
                      style="text-transform: none; text-decoration: underline!important;"
                      height="auto"
                    >
                      <span v-html="link.title" />
                    </v-btn>
                  </a>
                </p>
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            href="https://www.theschooloflostarts.com.au/events/extraordinaryencounters"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn :color="appSettings.dialogActionButtonColor" text>
              More Information
            </v-btn>
          </a>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "AboutModal",

  data() {
    return {
      dialog: true,
      links: [
        {
          url: "https://www.theschooloflostarts.com.au/our-story",
          title: "The School of Lost Arts"
        },
        {
          url: "https://livesmartlab.deakin.edu.au",
          title: `Deakin University, <br>
          Live + Smart Lab and IT Dept. Launch Pad`
        },

        {
          url: "https://tract.com.au/who-we-are/",
          title: "Tract Consultants Geelong"
        },

        {
          url: "https://www.onemap.com.au/",
          title: "OneMap"
        },

        {
          url: "https://www.cartdeco.com.au/about",
          title: "CartDeco Cartographics, Geelong"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
    });
  }
};
</script>

<style>
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
@media only screen and (max-width: 960px) {
  .contentWrapper {
    max-height: calc(100vh - 280px);
  }
}
</style>
