import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#ff0000",
        secondary: "#394246",
        accent: "#476B63",
        error: "#546B66"
        //"primary--text": "#ff0000"
      }
    }
  }
});
