<template>
  <v-app class="my-app">
    <Layout />
  </v-app>
</template>

<script>
import Layout from "./Layout.vue";

export default {
  name: "App",

  components: {
    Layout
  },

  data: () => ({
    //
  })
};
</script>
