export default new (class {
  async createIcons(map, markerList, baseurllocation) {
    //this dynamicly loads 60 images for each of the project marker
    var N = 60;
    try {
      N = markerList.data.features.length + 1;
    } catch (error) {
      N = 60;
    }
    const arr = [...Array(N + 1).keys()].slice(1);

    // this creates a blank image while the icons load.
    var width = 1;
    var bytesPerPixel = 4;
    var blankImage = new Uint8Array(width * width * bytesPerPixel);
    for (var x = 0; x < width; x++) {
      for (var y = 0; y < width; y++) {
        var offset = (y * width + x) * bytesPerPixel;
        blankImage[offset + 0] = 1; // red
        blankImage[offset + 1] = 1; // green
        blankImage[offset + 2] = 1; // blue
        blankImage[offset + 3] = 0; // alpha
      }
    }

    var genIcon = async function(map, blankImage, width, number) {
      //add dummy image
      map.addImage(number, {
        width: width,
        height: width,
        data: blankImage
      });
      await map.loadImage(
        `${window.location.origin}${baseurllocation}/icons/${number}.png`,
        async (error, image) => {
          if (error) throw error;
          map.removeImage(number); //remove dummy image
          //add loaded image
          map.addImage(number, image, { pixelRatio: 2 });
        }
      );
    };

    genIcon(map, blankImage, width, "icon0");

    const loadNumberIconsLoop = async () => {
      for (let index = 0; index < arr.length; index++) {
        // Get num of each fruit
        let number = "icon" + arr[index];
        genIcon(map, blankImage, width, number);
      }
    };

    await loadNumberIconsLoop();
  }

  renderMarkersCircles(map, appSettings) {
    // the marker icon:
    map.addLayer({
      id: "places-stackedCopy",
      type: "circle",
      source: "places",
      minzoom: 1,
      filter: [">", "stackIndex", 0],
      paint: {
        "circle-color": appSettings.styles.markerColours[1], //,
        "circle-radius": {
          stops: [
            [13, 4 * 1.25],
            [15, 10 * 1.25],
            [17, 16 * 1.25],
            [20, 30 * 1.25]
          ]
        }
      }
    });

    map.addLayer({
      id: "places",
      type: "circle",
      source: "places",
      minzoom: 1,
      filter: ["==", "hasStacking", false],
      paint: {
        "circle-color": appSettings.styles.markerColours[0], //,
        "circle-radius": {
          stops: [
            [13, 4],
            [15, 10],
            [17, 16],
            [20, 30]
          ]
        }
      }
    });

    //numbers
    map.addLayer({
      id: "place-numbers",
      type: "symbol",
      source: "places",
      minzoom: 14,
      filter: ["==", "hasStacking", false],
      layout: {
        "text-field": ["get", "indexLabel"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": {
          stops: [
            [13, 4],
            [15, 10],
            [17, 16],
            [20, 30]
          ]
        },

        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "text-allow-overlap": true,
        "text-ignore-placement": true
      },
      paint: {
        "text-color": appSettings.styles.markerText,

        "text-opacity": {
          stops: [
            [13, 0],
            [14, 1]
          ]
        }
      }
    });

    map.addLayer({
      id: "place-numbers-stacked",
      type: "symbol",
      source: "places",
      minzoom: 14,
      filter: ["==", "hasStacking", true],
      layout: {
        "text-field": ["get", "stackedIndexLabel"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": {
          stops: [
            [13, 4],
            [15, 10 * 0.8],
            [17, 16 * 0.8],
            [20, 30 * 0.8]
          ]
        },
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "text-allow-overlap": true,
        "text-ignore-placement": true
      },
      paint: {
        "text-color": appSettings.styles.markerText,
        "text-opacity": {
          stops: [
            [13, 0],
            [14, 1]
          ]
        }
      }
    });
  }
})();
