<template>
  <div>
    <v-toolbar :color="appSettings.menuBarColor">
      <v-tabs v-model="tab" color="teal darken-4 ">
        <v-tabs-slider color="teal"></v-tabs-slider>

        <v-tab
          v-for="tabItem in tabList"
          :key="tabItem"
          :disabled="
            tabItem == 'More Info' && !sidebarSite && sidebarSite !== 0
              ? true
              : false
          "
        >
          {{ tabItem }}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <v-tabs-items v-model="tab" class="tabcontent">
      <v-tab-item>
        <v-card flat>
          <SiteList :isOpen="tab === 0" />
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <SidebarSiteInfo />

        <v-card flat v-if="false">
          <v-card-text> sidebarSite:{{ sidebarSite }} </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SiteList from "./../components/SiteList.vue";
import SidebarSiteInfo from "./../components/info/SidebarSiteInfo.vue";

export default {
  components: {
    SiteList,
    SidebarSiteInfo
  },
  name: "SideBar",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      sidebarFocusList: state => state.navigation.sidebarFocusList,
      sidebarOpenInfo: state => state.navigation.sidebarOpenInfo,
      sidebarSite: state => state.navigation.sidebarSite
    }),
    sites() {
      return this.markerList.data.features;
    }
  },
  data() {
    return {
      tab: 0,
      tabList: ["Sites", "More Info"]
    };
  },
  watch: {
    tab: function(value) {
      this.$store.dispatch("navSetTabeState", value);
    },
    sidebarSite: function(index) {
      //if no site is selected for 300ms 3 times go back to sites

      if (this.sidebarSite === false && this.tab === 1) {
        setTimeout(() => {
          if (this.sidebarSite === false && this.tab === 1) {
            setTimeout(() => {
              if (this.sidebarSite === false && this.tab === 1) {
                this.tab = 0;
                //console.log("loading!!");
              }
            }, 150);
          }
        }, 150);
      }

      if (!isNaN(index) && this.sites[index] && this.sites[index].properties) {
        //this.tab = 1;
      }
    },
    sidebarFocusList: function(newValue) {
      if (newValue === true) {
        this.tab = 0;
      }
    },
    sidebarOpenInfo: function(newValue) {
      if (newValue === true) {
        if (
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          this.tab = 1;
        }
      }
    }
  }
};
</script>
<style>
.tabcontent {
  max-height: calc(100vh - 180px);
  max-height: calc(100vh - 116px);
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 1263px) {
  .tabcontent {
    max-height: calc(100vh - 80px);
  }
}
</style>
