<template>
  <div>
    <v-list three-line class="ma-0 pa-0">
      <v-list-item @click.prevent="loadIntro()">
        <v-list-item-avatar>
          <v-img src="/assets/introductionArtworkSmall.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            v-html="'Wadawurrung Introduction'"
          ></v-list-item-title>
          <v-list-item-subtitle
            v-html="'Listen to Wadawurrung Introduction by Corrina Eccles'"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list-item-group
      v-model="openPopupSiteAct"
      class="ma-0 pa-0"
      :cxolor="'red'"
      active-class="error--text"
    >
      <v-list three-line class="ma-0 pa-0">
        <template v-for="(site, index) in sites">
          <v-list-item
            v-bind:key="site.properties.index"
            v-bind:class="[
              'siteKeyAct_' + index,
              openPopupSite === index ? 'currentlyOpen' : ''
            ]"
            @click.prevent="focusOnSite(index)"
            v-on:dblclick="loadMoreInfo(index)"
          >
            <v-list-item-avatar
              class="xwhite--text"
              :style="{
                color: appSettings.styles.markerText,
                background:
                  appSettings.styles.markerColours[
                    site.properties.hasStacking ? 1 : 0
                  ]
              }"
            >
              {{ site.properties.indexLabel }}
            </v-list-item-avatar>

            <v-list-item-content class="secondary--text ">
              <v-list-item-title
                v-html="site.properties.Site_Name"
              ></v-list-item-title>

              <v-list-item-subtitle
                class="secondary--text font-weight-normal text--lighten-1"
                v-if="site.properties.Site_Artist"
                >{{ site.properties.Site_Artist }}
              </v-list-item-subtitle>

              <v-list-item-subtitle
                class="secondary--textfont-weight-light text--lighten-3"
                v-if="site.properties.Site_Address"
                >{{ site.properties.Site_Address }}
              </v-list-item-subtitle>
              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-list-item-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../main";

export default {
  name: "SiteList",
  props: {
    isOpen: Boolean
  },
  computed: {
    ...mapState({
      openPopupSite: state => state.navigation.openPopupSite,
      markerList: state => state.markers.markerList,
      baseurllocation: state => state.navigation.baseurllocation,
      appSettings: state => state.global.appSettings
    }),
    sites() {
      return this.markerList.data.features;
    },
    origin() {
      return window.location.origin;
    }
  },
  data: () => ({
    cancelScroll: null,
    openPopupSiteAct: false,
    holdScroll: false,
    oldSlectedSite: false
  }),
  methods: {
    loadIntro() {
      eventBus.$emit("loadIntroPopup");
    },
    loadMoreInfo(index) {
      if (this.oldSlectedSite === index) {
        this.$store.dispatch("navUpdateSidebarSite", index);
        this.$store.dispatch("navUpdateSidebarOpenToInfo");
      }
    },
    focusOnSite(index) {
      this.holdScroll = true;
      //if(this.cancelScroll){this.cancelScroll();}
      setTimeout(() => {
        this.holdScroll = false;
        //this.cancelScroll();
      }, 500);

      try {
        if (this.sites[index]) {
          let coordinates;
          coordinates = this.sites[index].geometry.coordinates
            ? this.sites[index].geometry.coordinates
            : this.sites[index].properties.Longitude &&
              this.sites[index].properties.Latitude
            ? [
                this.sites[index].properties.Longitude,
                this.sites[index].properties.Latitude
              ]
            : false;

          if (coordinates) {
            this.$store.dispatch("navUpdateFocusSite", index);
            try {
              //close menu if on mobile/tablet

              if (window.outerWidth < 1263) {
                setTimeout(() => {
                  this.$store.dispatch("navUpdateCloseMenu", true);
                }, 400);
              }
            } catch (error) {
              //do nothing
            }
          }
        }
      } catch (error) {
        //do nothing
      }
    },
    scrollToSite(newValue) {
      try {
        setTimeout(() => {
          if (!this.holdScroll) {
            let element = ".siteKeyAct_" + newValue;
            this.cancelScroll = this.$scrollTo(element);
          }
        }, 60);
      } catch (error) {
        //do nothing!
      }
    }
  },

  watch: {
    isOpen(newValue) {
      if (newValue === true) {
        this.scrollToSite(this.openPopupSiteAct);
      }
    },
    openPopupSite(newValue, oldValue) {
      setTimeout(() => {
        this.oldSlectedSite = newValue;
      }, 150);

      this.openPopupSiteAct = newValue;
      if ((!isNaN(newValue), newValue !== oldValue)) {
        if (!this.holdScroll) {
          this.scrollToSite(newValue);
        }
      }
    }
  },

  async mounted() {}
};
</script>
<style>
.currentlyOpen {
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #546b66 !important;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.3;
}
</style>
