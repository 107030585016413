<template>
  <v-card elevation="4" v-if="ready">
    <v-card-title
      :class="appSettings.headingTextColor"
      v-if="false && mappedMenuItems.length"
      >has items</v-card-title
    >
    <v-list
      dense
      :max-height="$vuetify.breakpoint.mobile ? '230px' : '310px'"
      :class="$vuetify.breakpoint.mobile ? 'custScroller' : 'custScroller'"
      style="overflow-y:auto"
    >
      <template v-for="(item, itemIndex) in mappedMenuItems">
        <v-list-item
          :key="item.index"
          @click="loadMarkerByIdFromMenu(item.index)"
        >
          <v-list-item-content>
            <v-list-item-title class="grey--text font-weight-normal"
              >{{ item.indexLabel }} - {{ item.Site_Name }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="item.header"
          v-if="mappedMenuItems.length - 1 != itemIndex"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    ready: false,
    showCarousel: false
  }),

  props: {
    getAudioDetails: Function,
    loadMarkerByIdFromMenu: Function,
    appSettings: Object,
    mappedMenuItems: {
      required: true,
      type: Array
    },
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    setTimeout(() => {
      this.ready = true;
      this.showCarousel = true;
    }, 10);
  },
  methods: {
    popupClicked() {
      //alert("Learn more clicked");
    }
  }
});
</script>

<style>
.mapboxgl-popup-content {
  box-shadow: none;
}
.galMenu,
.galMenu .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galMenu {
  height: 90px;
}
.galMenu .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
}

.custScroller::-webkit-scrollbar {
  width: 1em;
}

.custScroller::-webkit-scrollbar-track {
  xbox-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #f1f1f1;
}

.custScroller::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: 1px solid #a1a1a1;
}
</style>
