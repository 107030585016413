const getters = {};
const actions = {};
const state = {
  appSettings: {
    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    autoOpenMoreInfo: true,
    drawWidth: 300,
    drawButtonWidth: "286px",
    //mediaButtonColor: "light-green",
    mediaButtonColor: "#476b63",
    appButtonColor: "#476b63",
    dialogActionButtonColor: "#476b63",

    menuBarColor: "#b5c4c0",
    audioPlayColor: "476b63",
    drawWidthx: 300,
    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      markerColours: ["#003953", "#607d8b", "#f28cb1"],
      markerText: "#f6e9d8"
      //markerText: "#ffffff"
    }
  }
};
const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations
};
