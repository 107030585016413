<template>
  <div class="my-0 ">
    <v-btn
      depressed
      v-if="appSettings.validArSites[properties.Site_Number]"
      :color="appSettings.appButtonColor"
      :width="width"
      height="44px"
      style="text-transform: none;"
      class="my-1 mx-2  white--text text-center"
      href="https://apps.apple.com/au/app/geelong-nature-ar/id1556767586"
      target="_blank"
    >
      Geelong Nature A‪R App
      <br />
      Apple App Store
    </v-btn>

    <v-btn
      depressed
      v-if="properties.audioDescription_soundCloudId"
      :color="appSettings.mediaButtonColor"
      :width="width"
      class="my-1 mx-2 white--text"
      @click="
        setSound(properties.audioDescription_soundCloudId, properties.Site_Name)
      "
    >
      <v-icon left>{{ description_audioPlayIcon }}</v-icon>

      <span>{{ playDescriptionTitle }}</span>
    </v-btn>
    <v-btn
      depressed
      v-if="properties.siteAudio_soundCloudId"
      :color="appSettings.mediaButtonColor"
      :width="width"
      class="my-1 mx-2  white--text"
      @click="setSound(properties.siteAudio_soundCloudId, properties.Site_Name)"
    >
      <v-icon left>{{ siteAudio_audioPlayIcon }}</v-icon>

      <span>{{ playSiteAudioTitle }}</span>
    </v-btn>

    <div
      v-if="!$vuetify.breakpoint.smAndDown && appSettings.autoOpenMoreInfo"
      style="height:3px"
    ></div>
  </div>
</template>

<script>
export default {
  name: "AudioPlayButton",
  components: {},
  data: () => ({
    userAgent: "",
    isIpad: false,
    ready: false,
    description_audioPlayIcon: "",
    siteAudio_audioPlayIcon: "",
    playTitle: "",
    interval: false,
    playDescriptionTitle: "",
    playSiteAudioTitle: ""
  }),

  props: {
    basicTitle: Boolean,
    isPopup: Boolean,
    getAudioDetails: Function,
    setSound: Function,
    width: String,
    appSettings: Object,
    properties: {
      required: true,
      type: Object
    }
  },
  computed: {},
  methods: {
    updatePlayTitle(type, name, id) {
      //console.log("updatePlayTitle run");
      let tempAudio = this.getAudioDetails(name, id);

      let tempIcon = "false";
      let title = "";
      if (tempAudio && !tempAudio.isLoadedThisTrack) {
        title = "Play Audio";
        tempIcon = "mdi-play-outline";
        if (
          this.$browserDetect.isIOS ||
          this.$browserDetect.isChromeIOS ||
          this.$browserDetect.isSafari ||
          this.isIpad
        ) {
          title = "Load Audio";
          tempIcon = "mdi-volume-high";
        }
      } else if (!tempAudio.isPlaying && tempAudio.isLoadedThisTrack) {
        title = "Play Audio";
        tempIcon = "mdi-play-outline";
      } else if (tempAudio.isPlayingThisTrack) {
        title = "Pause Audio";
        tempIcon = "mdi-pause";
      } else {
        title = "Load Audio";
        tempIcon = "mdi-cassette";
      }

      this.playTitle = title;
      let playDescriptionTitle = title.replace("Audio", "Description");
      let playSiteAudioTitle = title.replace("Audio", "Site Audio");

      //this.playDescriptionTitle = this.playTitle;
      //this.description_audioPlayIcon = tempIcon;
      if (this.basicTitle) {
        if (type === "Description") {
          this.playDescriptionTitle = this.playTitle;
          this.description_audioPlayIcon = tempIcon;
        } else {
          this.playSiteAudioTitle = this.playTitle;
          this.siteAudio_audioPlayIcon = tempIcon;
        }
      } else if (!this.basicTitle) {
        if (type === "Description") {
          this.playDescriptionTitle = playDescriptionTitle;
          this.description_audioPlayIcon = tempIcon;
        } else {
          this.playSiteAudioTitle = playSiteAudioTitle;
          this.siteAudio_audioPlayIcon = tempIcon;
        }
      }
    }
  },

  async mounted() {
    var userAgent = window.navigator.userAgent;
    this.userAgent = userAgent;
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/Macintosh/i)
    ) {
      this.isIpad = true;
    } else {
      // Anything else
    }

    let _this = this;
    _this.updatePlayTitle(
      "Description",
      _this.properties.Site_Name,
      _this.properties.audioDescription_soundCloudId
    );
    _this.updatePlayTitle(
      "Site Audio",
      _this.properties.Site_Name,
      _this.properties.siteAudio_soundCloudId
    );

    _this.interval = setInterval(function() {
      _this.updatePlayTitle(
        "Description",
        _this.properties.Site_Name,
        _this.properties.audioDescription_soundCloudId
      );
      _this.updatePlayTitle(
        "Site Audio",
        _this.properties.Site_Name,
        _this.properties.siteAudio_soundCloudId
      );
    }, 150);
  },
  async destroyed() {
    clearInterval(this.interval);
  }
};
</script>
