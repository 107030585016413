import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import markers from "./modules/markers.js";
import navigation from "./modules/navigation.js";
import media from "./modules/media.js";
import global from "./modules/global.js";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    global,
    media,
    markers,
    navigation
  }
});
