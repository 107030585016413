<template>
  <div class="mapWrapper">
    <mapbox
      :access-token="accessToken"
      :map-options="options"
      @map-load="loaded"
      @map-init="initialized"
      :nav-control="{
        show: true,
        position: 'top-left'
      }"
      :geolocate-control="{
        show: true,
        position: 'top-left',
        options: {
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        }
      }"
      :scale-control="{
        show: false,
        position: 'top-left'
      }"
      :fullscreen-control="{
        show: true,
        position: 'top-left'
      }"
      @map-zoomend="zoomend"
    />
  </div>
</template>

<script>
//cluster ajsdflj https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

import { mapState } from "vuex";

import Mapbox from "mapbox-gl-vue";
//import mapboxgl from "mapbox-gl";

import PopupStackedMenu from "./../info/PopupStackedMenu.vue";
import PopupContent from "./../info/PopupContent.vue";
import markerService from "./services/markerService.js";
export default {
  components: { Mapbox },
  name: "Map",
  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings,
      playingSiteName: state => state.media.playingSiteName,
      currentTrack: state => state.media.currentTrack,
      isPlaying: state => state.media.isPlaying,

      //markers:
      stackedMarkers: state => state.markers.stackedMarkers,
      markerList: state => state.markers.markerList,

      //sites:
      focusSite: state => state.navigation.focusSite,
      baseurllocation: state => state.navigation.baseurllocation
    }),
    sites() {
      return this.markerList.data.features;
    }
  },

  props: {
    msg: String
  },
  data: () => ({
    useCluster: false,
    useCircles: true,
    popups: [],
    map: null,
    accessToken:
      //"pk.eyJ1IjoiYXNpbXMiLCJhIjoiMDhlX2hTUSJ9.Sqq7Mq0CPccTk_WLqKjb4A",
      "pk.eyJ1IjoiY2FydGRlY28iLCJhIjoiMkRSMXQzUSJ9.74YN7Lx-RgcpjBPWjtGcog",
    options: {
      container: "mapContainer",
      //style: "mapbox://styles/mapbox/streets-v11",
      style: "mapbox://styles/cartdeco/ckhjzzidj32kr19qqiok0tyqy",
      center: [144.3617, -38.1499],
      //padding: { top: 10, bottom: 25, left: 15, right: 700 },
      zoom: 15,
      maxZoom: 20,
      maxBounds: [
        [144.2978, -38.2058],
        [144.4928, -38.0562]
        //[143, -40],
        //[145, -36]
      ],
      pitch: 30, // pitch in degrees
      bearing: -10 // bearing in degrees
    }
  }),
  async mounted() {},
  watch: {
    focusSite: function(index) {
      if (!isNaN(index) && this.sites[index] && this.sites[index].properties) {
        this.createPopupFromIndex(index);
      }
    }
  },
  methods: {
    setSound(soundCloudId, Site_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", { soundCloudId, Site_Name });
    },
    openSidebar(index) {
      //if fullscreen mode go back to normal
      try {
        let [FullscreenControl] = this.map._controls.filter(
          value => value.constructor.name == "FullscreenControl"
        );
        if (FullscreenControl._fullscreen === true) {
          FullscreenControl._onClickFullscreen();
        }
      } catch (error) {
        //do nothing
      }

      this.$store.dispatch("navUpdateSidebarSite", index);
      this.$store.dispatch("navUpdateSidebarOpenToInfo");
    },
    updatePaddingZoomForRemotePopup(coordinates, flyTo) {
      if (window.outerHeight < 700 || this.$vuetify.breakpoint.mobile) {
        this.map.setPadding({ bottom: (window.outerHeight - 80) / 2 });
      } else {
        this.map.setPadding({ bottom: 0 });
      }

      if (flyTo) {
        let options = {
          speed: this.useCluster ? 0.5 : 1,
          center: coordinates,
          zoom: this.map.getZoom() < 18 ? 18.4 : this.map.getZoom()
        };

        this.map.flyTo(options);
      }
    },
    createPopupFromIndex(index) {
      try {
        let properties = this.sites[index].properties;
        let coordinates = this.sites[index].geometry.coordinates;
        this.updatePaddingZoomForRemotePopup(coordinates, true);

        this.createPopup(coordinates, properties);
      } catch (error) {
        //error loacing popup
      }
    },
    getAudioDetails(name, id) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isPlayingThisTrack = true;
      }

      if (
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isLoadedThisTrack = true;
      }

      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },
    loadMarkerByIdFromMenu(index) {
      this.clearAllPopups();
      this.$store.dispatch("navUpdateSidebarFocusList");

      try {
        let properties = this.sites[index].properties;
        let coordinates = this.sites[index].geometry.coordinates;

        this.createNormalPopup(coordinates, properties);

        if (this.map.getZoom() < 18) {
          this.updatePaddingZoomForRemotePopup(coordinates, true);
        }
      } catch (error) {
        //do nothing;
      }
    },
    createPopup(coordinates, properties, allowStacking) {
      this.clearAllPopups();
      this.$store.dispatch("navUpdateSidebarFocusList");
      if (
        allowStacking &&
        properties.hasStacking &&
        this.stackedMarkers[properties.geoIndex]
      ) {
        let stackMenu = this.stackedMarkers[properties.geoIndex];
        let mappedMenuItems = stackMenu.map(
          item => this.sites[item].properties
        );

        this.createStackedMenuPopup(coordinates, mappedMenuItems);
      } else {
        this.createNormalPopup(coordinates, properties);
      }
    },
    createStackedMenuPopup(coordinates, mappedMenuItems) {
      //console.log("mappedMenuItems", mappedMenuItems);
      let newPopup = new window.mapboxgl.Popup({
        className: "galMenu",
        closeButton: false
      })
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .setOffset(15)
        .addTo(this.map);

      this.popups.push(newPopup);

      new PopupStackedMenu({
        propsData: {
          mappedMenuItems,
          loadMarkerByIdFromMenu: this.loadMarkerByIdFromMenu,
          vuetifyCopy: this.$vuetify,
          appSettings: this.appSettings
        }
      }).$mount("#vue-popup-content");
    },

    createNormalPopup(coordinates, properties) {
      let newPopup = new window.mapboxgl.Popup({
        className: "galPopup",
        //closeOnMove: true
        closeButton: false
      })
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .setOffset(15)
        .addTo(this.map);

      this.popups.push(newPopup);

      new PopupContent({
        propsData: {
          properties: properties,
          clearAllPopups: this.clearAllPopups,
          getNavTabState: this.getNavTabState,
          vuetifyCopy: this.$vuetify,
          browserDetectCopy: this.$browserDetect,
          openSidebar: this.openSidebar,
          setSound: this.setSound,
          getAudioDetails: this.getAudioDetails,
          appSettings: this.appSettings
        }
      }).$mount("#vue-popup-content");

      let _selfStore = this.$store;
      newPopup.on("close", function(/*e*/) {
        //_selfStore.commit("updateOpenPopupSite", false);
        //_selfStore.commit("updateSidebarSite", false);

        _selfStore.dispatch("navUpdateOpenPopupSite", false);
        _selfStore.dispatch("navUpdateSidebarSite", false);

        // do what you need to do!
      });
      setTimeout(() => {
        this.$store.dispatch("navUpdateOpenPopupSite", properties.index);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
      }, 30);

      window.gtag("event", "popupOpen_" + properties.index);
    },
    clearAllPopups() {
      this.$store.dispatch("navUpdateOpenPopupSite", false);
      this.popups.forEach(popup => {
        popup.remove();
      });
      this.popups = [];
    },
    initialized(/*map*/) {},

    async loaded(map) {
      map.addSource("attribution", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: []
        },
        attribution: "Your custom attribution"
      });

      window.clearAllPopups = this.clearAllPopups.bind(this);
      map._isVue = true;
      this.map = map;

      if (this.useCluster === true) {
        let clusterZoomLevl = 17; //this.$vuetify.breakpoint.mobile ? 17 : 18; //when to use clusters that are tight or loose

        this.map.addSource("placesForCluster", {
          ...this.markerList,
          ...{
            cluster: true,
            clusterMaxZoom: 24,
            clusterRadius: 50,
            maxzoom: 24
          }
        });

        this.map.addSource("placesForClusterZoomed", {
          ...this.markerList,
          ...{
            cluster: true,
            clusterMaxZoom: 24,
            clusterRadius: 10,
            maxzoom: 24
          }
        });
        markerService.renderMarkersClusters(this.map, {
          sourceName: "placesForCluster",
          preFix: "",
          minZoomValue: 0,
          maxZoomValue: clusterZoomLevl
        });
        markerService.renderMarkersClusters(
          this.map,
          {
            sourceName: "placesForClusterZoomed",
            preFix: "zoom_",
            minZoomValue: clusterZoomLevl - 0.001,
            maxZoomValue: 24
          },
          this.appSettings
        );
      }

      this.map.addSource("places", { ...this.markerList });

      let preLoadIcons = false;

      if (preLoadIcons) {
        await markerService.createIcons(
          this.map,
          this.markerList,
          this.baseurllocation
        );
      }

      if (this.useCluster !== true) {
        if (this.useCircles) {
          markerService.renderMarkersCircles(this.map, this.appSettings);
        } else {
          markerService.renderMarkersIcons(this.map, this.appSettings);
        }
      }

      let createStackedMenuPopup = this.createStackedMenuPopup;

      this.map.on("click", "places", e => {
        let coordinates = e.features[0].geometry.coordinates.slice();
        let properties = e.features[0].properties;
        this.createPopup(coordinates, properties, true);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
      });

      if (this.useCircles && !this.useCluster) {
        this.map.on("click", "places-stackedCopy", e => {
          let coordinates = e.features[0].geometry.coordinates.slice();
          let properties = e.features[0].properties;
          this.createPopup(coordinates, properties, true);
          this.$store.dispatch("navUpdateSidebarSite", properties.index);
        });
      }

      this.map.on("click", "zoom_places", e => {
        let coordinates = e.features[0].geometry.coordinates.slice();
        let properties = e.features[0].properties;
        this.createPopup(coordinates, properties, true);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
      });

      this.map.on("click", "zoom_clusters", function(e) {
        //console.log("clusters clicked on", e);
        var features = map.queryRenderedFeatures(e.point, {
          layers: ["zoom_clusters"]
        });
        //console.log("features", features);

        var clusterId = features[0].properties.cluster_id,
          point_count = features[0].properties.point_count,
          clusterSource = map.getSource("placesForClusterZoomed");

        clusterSource.getClusterLeaves(clusterId, point_count, 0, function(
          err,
          aFeatures
        ) {
          let mappedMenuItems = aFeatures.map(item => item.properties);
          //console.log("mappedMenuItems", mappedMenuItems);
          let coordinates = [e.lngLat.lng, e.lngLat.lat];
          createStackedMenuPopup(coordinates, mappedMenuItems);
        });
      });

      this.map.on("click", "clusters", function(e) {
        //console.log("clusters clicked on", e);
        var features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"]
        });
        //console.log("features", features);

        var clusterId = features[0].properties.cluster_id,
          point_count = features[0].properties.point_count,
          clusterSource = map.getSource("placesForCluster");

        //console.log("clusterId", clusterId);

        /*
        map
          .getSource("places")
          .getClusterExpansionZoom(clusterId, function(err, zoom) {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          });
        clusterSource.getClusterChildren(clusterId, function(err, aFeatures) {
          //console.log("getClusterChildren", err, aFeatures);
        });*/

        // Get all points under a cluster
        clusterSource.getClusterLeaves(clusterId, point_count, 0, function(
          err,
          aFeatures
        ) {
          let mappedMenuItems = aFeatures.map(item => item.properties);
          //console.log("mappedMenuItems", mappedMenuItems);
          let coordinates = [e.lngLat.lng, e.lngLat.lat];
          createStackedMenuPopup(coordinates, mappedMenuItems);
        });
      });

      this.map.on("click", "places", e => {
        let coordinates = e.features[0].geometry.coordinates.slice();
        let properties = e.features[0].properties;
        this.createPopup(coordinates, properties, true);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.on("mouseenter", "places", () => {
        this.map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      this.map.on("mouseleave", "places", () => {
        this.map.getCanvas().style.cursor = "";
      });
    },

    zoomend(/*map, e*/) {
      //console.log(this.map.getZoom());
    } /*,
    clicked(map, e) {
      //const title = e.features[0].properties.title;
    }*/
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.container > div > .mapWrapperWrapper {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100vw;
  max-width: 100vw !important;
  border: none;
}

#mapContainer {
  height: 100%;
  width: 100vw;
  padding: 0;
  margin: 0;
}
</style>
