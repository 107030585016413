<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? 500 : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <font v-if="!$vuetify.breakpoint.xsOnly" class="secondary--text "
            >Listen to </font
          >Wadawurrung Introduction<font
            v-if="!$vuetify.breakpoint.xsOnly"
          ></font>
        </span>
      </template>

      <v-card style="overflow:hidden">
        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row>
            <v-col
              cols="12"
              style="overflow:hidden;max-height:100%;margin-bottom:-30px"
              v-if="$vuetify.breakpoint.xsOnly && false"
            >
              <v-img
                v-if="$vuetify.breakpoint.xsOnly && false"
                class="white--text align-end"
                height="100px"
                src="/assets/introductionArtwork.jpg"
              >
              </v-img>
            </v-col>

            <v-col
              cols="6"
              style="overflow:hidden;max-height:100%"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              <v-img
                v-if="$vuetify.breakpoint.mdAndUp"
                class="white--text align-end"
                xheight="300px"
                src="/assets/introductionArtwork.jpg"
              >
              </v-img>

              <v-img
                v-if="$vuetify.breakpoint.smOnly"
                class="white--text align-end"
                height="100%"
                src="/assets/introductionArtworkThin.jpg"
              >
              </v-img>
            </v-col>
            <v-col sm="6" cols="12">
              <v-card-text
                class="black-text text-center font-weight-medium   mb-0 pb-0"
              >
                <h2 class="font-weight-medium   secondary--text ">
                  Wadawurrung Introduction
                </h2>
              </v-card-text>
              <v-card-text
                class=" secondary--text text-center font-weight-medium  mb-0 pb-0"
              >
                Listen to an introduction by Corrina Eccles
              </v-card-text>

              <v-card-text
                class="black-text text-center font-weight-medium  mb-0 pb-0"
              >
                <AudioPlayButton
                  :basicTitle="true"
                  :setSound="setSound"
                  :getAudioDetails="getAudioDetails"
                  :properties="properties"
                  :width="'200px'"
                  :appSettings="appSettings"
                />
                <v-card-text
                  class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
                >
                  Welcome to The Journey of Extraordinary Encounters, the audio
                  will continue playing once you close this dialog, you can
                  begin exploring.
                </v-card-text>
                <br />
                <v-card-text
                  v-if="$vuetify.breakpoint.mdAndDown"
                  class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
                  ><v-img
                    src="/assets/Location.jpg"
                    height="40"
                    width="40"
                    style="border-radius:4px; float:left ;;"
                    center
                  ></v-img>
                  To display your current location on the map please press the
                  “locate” button positioned in the top left corner of the map.
                </v-card-text>
              </v-card-text>

              <v-card-text>
                <p></p>
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            v-if="false"
            href="https://www.theschooloflostarts.com.au/events/extraordinaryencounters"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn :color="appSettings.dialogActionButtonColor" text>
              More Information
            </v-btn>
          </a>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="loadAbout()"
          >
            About The Journey
          </v-btn>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AudioPlayButton from "./../ui/AudioPlayButton.vue";
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "IntroductionModal",
  components: {
    AudioPlayButton
  },
  data() {
    return {
      openIntro: false,
      properties: {
        audioDescription_soundCloudId: "987771763",
        Site_Name: "Introduction"
      }
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      //sidebarSite: state => state.navigation.sidebarSite,
      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying
    }),
    isPlayingThisTrack() {
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === this.properties.Site_Name
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },
    getAudioDetails(name) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name
      ) {
        isPlayingThisTrack = true;
      }

      if (this.playingSiteName && this.playingSiteName === name) {
        isLoadedThisTrack = true;
      }
      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },

    setSound(soundCloudId, Site_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", { soundCloudId, Site_Name });
    }
  },
  created() {
    //console.log(this.$vuetify.breakpoint);
    /*
    if (localStorage.hasRun === "yes") {
      //console.log("has loaded");
      localStorage.hasRun = null;
      this.openIntro = false;
    } else {
      //console.log("not loaded before!!");
      this.openIntro = true;
      localStorage.hasRun = "yes";
    }
*/

    eventBus.$on("loadIntroPopup", () => {
      this.openIntro = true;
    });
  },
  mounted() {
    //console.log("mounted!!!", this.openIntro);

    setTimeout(() => {
      this.openIntro = true;
    }, 300);
  }
};
</script>

<style>
@media only screen and (min-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100%;
  }
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
