var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"three-line":""}},[_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.loadIntro()}}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":"/assets/introductionArtworkSmall.jpg"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('Wadawurrung Introduction')}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s('Listen to Wadawurrung Introduction by Corrina Eccles')}})],1)],1)],1),_c('v-list-item-group',{staticClass:"ma-0 pa-0",attrs:{"cxolor":'red',"active-class":"error--text"},model:{value:(_vm.openPopupSiteAct),callback:function ($$v) {_vm.openPopupSiteAct=$$v},expression:"openPopupSiteAct"}},[_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"three-line":""}},[_vm._l((_vm.sites),function(site,index){return [_c('v-list-item',{key:site.properties.index,class:[
            'siteKeyAct_' + index,
            _vm.openPopupSite === index ? 'currentlyOpen' : ''
          ],on:{"click":function($event){$event.preventDefault();return _vm.focusOnSite(index)},"dblclick":function($event){return _vm.loadMoreInfo(index)}}},[_c('v-list-item-avatar',{staticClass:"xwhite--text",style:({
              color: _vm.appSettings.styles.markerText,
              background:
                _vm.appSettings.styles.markerColours[
                  site.properties.hasStacking ? 1 : 0
                ]
            })},[_vm._v(" "+_vm._s(site.properties.indexLabel)+" ")]),_c('v-list-item-content',{staticClass:"secondary--text "},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(site.properties.Site_Name)}}),(site.properties.Site_Artist)?_c('v-list-item-subtitle',{staticClass:"secondary--text font-weight-normal text--lighten-1"},[_vm._v(_vm._s(site.properties.Site_Artist)+" ")]):_vm._e(),(site.properties.Site_Address)?_c('v-list-item-subtitle',{staticClass:"secondary--textfont-weight-light text--lighten-3"},[_vm._v(_vm._s(site.properties.Site_Address)+" ")]):_vm._e(),_c('v-list-item-subtitle')],1)],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }