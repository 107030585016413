<template>
  <div>
    <v-navigation-drawer
      touchless
      v-model="drawer"
      app
      right
      :width="appSettings.drawWidth"
    >
      <SideBar />
    </v-navigation-drawer>

    <v-app-bar
      flat
      clipped-left
      app
      :color="appSettings.menuBarColor"
      class="secondary--text"
    >
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <SoundCloudPlayer />

      <v-toolbar-title class="">
        {{ playingSiteName }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-title
        v-if="!(hasPlayingSiteName && $vuetify.breakpoint.mobile)"
      >
        <div v-if="false">
          <span class="font-weight-light"> Geelong </span>
          <span>{{ "Nature Map" }}</span>
        </div>
        <div class=" hidden-sm-and-down">
          <span class="font-weight-light">
            The Journey of
          </span>
          <span>Extraordinary Encounters</span>
        </div>

        <div class=" hidden-md-and-up" style="font-size: 0.9em;">
          <div class="font-weight-light">
            The Journey of
          </div>
          <div style="margin-top:-9px">Extraordinary Encounters</div>
        </div>
      </v-toolbar-title>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main>
      <v-container fluid class="mapFrame" style="margin: 0;padding: 0;">
        <v-layout fill-height>
          <Map />
        </v-layout>
      </v-container>
    </v-main>

    <v-footer app :color="appSettings.menuBarColor">
      <span>&copy; {{ new Date().getFullYear() }}</span>
      <v-spacer></v-spacer>


            <a
        href="http://maptivate.com.au/"
        target="_blank"
        style="text-decoration: none!important;"
      >
        <span class="secondary--text mr-8">
          <font v-if="!$vuetify.breakpoint.xsOnly">Powered by </font>

          Maptivate
        </span>
      </a>
      <YouTubeModal />
      <IntroductionModal class="mr-8" />

      <AboutModal />
    </v-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import RulesDialog from "./components/dialogs/RulesDialog";

import Map from "./components/map/Map.vue";
import SideBar from "./components/SideBar.vue";
import AboutModal from "./components/info/AboutModal.vue";
import YouTubeModal from "./components/controls/YouTubeModal.vue";
import IntroductionModal from "./components/info/IntroductionModal.vue";

import SoundCloudPlayer from "./components/controls/SoundCloudPlayer.vue";

export default {
  components: {
    Map,
    SideBar,
    AboutModal,
    SoundCloudPlayer,
    YouTubeModal,
    IntroductionModal
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      sidebarOpenInfo: state => state.navigation.sidebarOpenInfo,
      sidebarSite: state => state.navigation.sidebarSite,
      closeMenu: state => state.navigation.closeMenu,
      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying
    }),
    sites() {
      return this.markerList.data.features;
    },
    hasPlayingSiteName() {
      if (!this.playingSiteName || this.playingSiteName == "") {
        return false;
      } else {
        return true;
      }
    }
  },
  data: () => ({
    appTitle: "Geelong Nature Map",
    drawer: null
  }),
  methods: {
    track() {
      this.$ga.page("/");
    },
    setSound(soundCloudId, Site_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", { soundCloudId, Site_Name });
    },
    setCommand(command) {
      this.$store.dispatch("mediaUpdateCommand", command);
    }
  },
  created() {},
  mounted() {},
  watch: {
    closeMenu: function(newValue) {
      if (newValue === true) {
        this.drawer = false;
        this.$store.dispatch("navUpdateCloseMenu", false);
      }
    },
    sidebarOpenInfo: function(index) {
      if (index === true) {
        this.drawer = true;

        setTimeout(() => {
          this.$store.dispatch("navClearSidebarOpenToInfo");
        }, 100);

        if (
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          //open menu
          this.drawer = true;
        }
      }
    }
  }
};
</script>

<style>
.mapFrame,
.mapWrapper,
#mapContainer {
  xheight: calc(100vh - 100px);
  height: calc(100vh - 100px);

  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1263px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 100px);
  }
}
@media only screen and (max-width: 959px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 90px);
  }
}
.v-card__title {
  word-break: initial;
}
</style>
